import React from 'react'
import styled from 'styled-components' 
import { graphql } from 'gatsby'

import Seo from './../components/Layout/Seo'
import TitleWithButton from './../components/TitleWithButton'

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100vh;
    padding: 0 3rem;

    text-align: center;
`

const StyledTitleWithButton = styled(TitleWithButton)`
    text-align: center;

    .line {
        display: none;
    }

    .description {
        max-width: 55rem;

        margin: 0 auto 2rem auto;
    }

    ${props => props.theme.below.desktop`
        .button-circle {
            display: block;
        }
    `}
`

const NotFoundPage = ({
    pageContext: {
        langSlug
    },
    data: {
        prismicNotFoundPage: {
            data: {
                title,
                description,
                button_label,
                button_link
            }
        }
    }
}) => {
    return (
        <>
            <Seo 
                title='Oops! Page not found..'
            />
            <Wrapper>
                <StyledTitleWithButton
                    lang={langSlug}
                    title={title.text}
                    description={description.html}
                    button={{
                        type: 'arrow',
                        direction: 'right',
                        label: button_label,
                        to: button_link.slug || ''
                    }}
                />
            </Wrapper>
        </>
    )
}

export const QUERY_NOTFOUNDPAGE = graphql`
    query NotFoundPage($id: String!) {
        prismicNotFoundPage(id: { eq: $id }) {
            id
            lang
            data {
                title {
                    text
                }
                description {
                    html
                }
                button_label
                button_link {
                    slug
                }
            }
        }
    }
`

export default NotFoundPage
